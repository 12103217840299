import './styles.sass'
import React, { Component } from 'react'
import c from 'classnames'
import PropTypes from 'prop-types'
import Page from 'src/components/ui/page'

export default class Work extends Component {
  static propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
  }

  static defaultProps = {
    className: '',
    style: null,
  }

  get className() {
    const { className } = this.props
    return c('dc-work', className)
  }

  render() {
    const { style } = this.props
    const { className } = this
    return (
      <Page className={className} style={style} id="work" title="Work">
        Work
      </Page>
    )
  }
}
